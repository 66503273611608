<template>
  <div class="tips">
    <div class="flex-row-center content">
      <el-image class="tips-img" :src="images[0]"></el-image>
      <div class="tips-text">{{ $t("tipsMessage.review1") }}</div>
      <div class="tips-text">{{ $t("tipsMessage.review2") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tips-review",
  data() {
    return {
      images: [require(`../../static/img/tips-wait.png`)]
    };
  }
};
</script>

<style scoped lang="scss">
.tips {
  width: 100%;
  height: calc(100% - 60px);
  position: fixed;
  left: 0;
  top: 60px;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, #cafdfb 0%, #6ee8df 100%);
  .content {
    text-align: center;
    display: -webkit-flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    min-height: 500px;
    .tips-img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
    .tips-text {
      font-size: 30px;
      font-family: "PingFangSC-Regular, PingFang SC";
      font-weight: 400;
      color: #000000;
      line-height: 42px;
      margin-bottom: 20px;
    }
  }
}
</style>